<template>
  <div>
    <flixDashboard>
      <template v-slot:content>
        <div class="flix-container">
          <flixCheckPermissions role="service" permission="2" error>
            <services />
          </flixCheckPermissions>
        </div>
      </template>
    </flixDashboard>
  </div>
</template>

<script>
export default {
  components: {
    services () { return import('@/components/services') }
  }
}
</script>
